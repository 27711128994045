import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import {
  useNavigate,
  useLocation
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import BannerDesktop from './features/banner/BannerDesktop'
import HamburgerMenu from './features/hamburger-menu/HamburgerMenu'
import XIAMRouters from './features/XIAMRouters/XIAMRouters'
import {
  selectOverlayError,
  setOverlayError,
  selectOAuthError
} from './features/error/errorSlice'
import './App.scss'
import {
  BANNER_MENU_SECTIONS,
  BANNER_DROPDOWN_MENU_SECTIONS,
  GA_ID,
  HAMBURGER_MENU_SECTIONS
} from './util/config'
import './util/i18n'
import isMobileView from './util/MobileView'
import { selectUserObject } from './features/user/userSlice'
import { selectProfilePictureImage } from './features/user/profilePictureSlice'
import BannerMobile from './features/banner/BannerMobile'
import { ErrorPage } from './features/error/ErrorPage'
import { isAuthenticated } from './util/auth'
import { fetchSurveyTemplates } from './pages/Survey/surveyTemplateSlice'

if (GA_ID) {
  ReactGA.initialize(GA_ID)
}

const Content = React.memo(() => {
  const overlayError = useSelector(selectOverlayError)
  const { t, i18n } = useTranslation()
  const location = useLocation().pathname
  const navigate = useNavigate()
  const oauthError = useSelector(selectOAuthError)
  const dispatch = useDispatch()

  const hasError = oauthError || overlayError

  const [isMobile, setIsMobile] = useState(isMobileView())

  const [sideMenuHidden, setSideMenuHidden] = useState(true)

  const hideMenu = () => {
    setSideMenuHidden(true)
  }

  const userObjData = useSelector(selectUserObject)
  const userProfilePictureData = useSelector(selectProfilePictureImage)
  const userInfo = {
    profilePictureData: userProfilePictureData,
    firstName: userObjData.firstName,
    lastName: userObjData.lastName,
    lastLogin: userObjData.lastLogin,
  }

  useEffect(() => {
    const preferredLang = userObjData.preferredLanguage
    if (Array.isArray(i18n.resourceKeys) && i18n.resourceKeys.includes(preferredLang)) {
      i18n.changeLanguage(preferredLang)
    }
  }, [userObjData])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileView())
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    dispatch(fetchSurveyTemplates())

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // if (!authState) {
  //   return null
  // }

  // desktop view
  if (!isMobile) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12}>
            {isAuthenticated ? (
              <BannerDesktop
                userInfo={userInfo}
                links={BANNER_MENU_SECTIONS}
                dropdownMenuLinks={BANNER_DROPDOWN_MENU_SECTIONS}
                showUserProfile={isAuthenticated}
                signInButtonText={t('Sign Out')}
                onSignIn={() => navigate('/logout')}
                errorBar={hasError}
              />
            ) : (
              <BannerDesktop
                userInfo={userInfo}
                links={[]}
                dropdownMenuLinks={[]}
                showUserProfile={isAuthenticated}
                signInButtonText={t('sign-out-tab')}
                onSignIn={() => navigate('/login')}
                errorBar={hasError}
              />
            )}
            {overlayError.message && location !== '/health' ? (<ErrorPage />
            ) : (
              <Box sx={{ flexGrow: 1, width: 'calc(100% - widthOfSideMenu)', ml: 'widthOfSideMenu' }}>
                <XIAMRouters />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    )
  }

  // mobile view
  const shouldShowToolbar = isAuthenticated && sideMenuHidden && location !== '/login'
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', overflowX: 'hidden' }}>
      {(shouldShowToolbar || overlayError.message) && (
        <AppBar position="absolute" sx={{ backgroundColor: '#F9F8F7' }}>
          <Toolbar>
            {!overlayError.message && (
              <IconButton onClick={() => { setSideMenuHidden(false) }}>
                <MenuIcon style={{ color: '#564C47' }} />
              </IconButton>
            )}
            <BannerMobile
              userInfo={userInfo}
              showUserProfile={isAuthenticated}
              hasError={hasError}
            />
          </Toolbar>
        </AppBar>
      )}

      {isAuthenticated && (
        <Box sx={{ display: 'flex' }}>
          {/* eslint-disable-next-line max-len */}
          {!overlayError.message && (
            <HamburgerMenu
              menuLinks={HAMBURGER_MENU_SECTIONS}
              userInfo={userInfo}
              isHidden={sideMenuHidden}
              sideMenuClose={hideMenu}
            />
          )}
          <Box sx={{ flexGrow: 1 }}>
            {overlayError.message && location !== '/health' ? (
              <ErrorPage />
            ) : (
              sideMenuHidden && (
                <>
                  {shouldShowToolbar && <Toolbar />}
                  <XIAMRouters />
                </>
              )
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
})

function App() {
  const dispatch = useDispatch()
  const overlayError = useSelector(selectOverlayError)

  useEffect(() => {
    dispatch(setOverlayError({ message: overlayError.message || '', code: overlayError.code || undefined }))
  }, [overlayError, dispatch])

  const [isErrorAlertOpen, setIsErrorAlertOpen] = React.useState(false)

  const closeErrorAlert = () => {
    setIsErrorAlertOpen(false)
  }

  return (
    <div className="App">
      <Content
        isErrorAlertOpen={isErrorAlertOpen}
        closeErrorAlert={closeErrorAlert}
        overlayError={overlayError}
      />
    </div>
  )
}

Content.propTypes = {
  overlayError: PropTypes.shape({
    message: PropTypes.string
  }).isRequired
}

export default App
