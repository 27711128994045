import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import enUSTranslations from '../features/locales/en-US.json'
import esESTranslations from '../features/locales/es-ES.json'
import jaJATranslations from '../features/locales/ja-JP.json'
import koKOTranslations from '../features/locales/ko-KR.json'
import nlNLTranslations from '../features/locales/nl-NL.json'
import deDETranslations from '../features/locales/de-DE.json'
import ptPTTranslations from '../features/locales/pt-PT.json'
import frFRTranslations from '../features/locales/fr-FR.json'

const langResources = {
  'en-US': {
    translation: enUSTranslations,
  },
  'es-ES': {
    translation: esESTranslations,
  },
  'ja-JP': {
    translation: jaJATranslations,
  },
  'ko-KR': {
    translation: koKOTranslations,
  },
  'nl-NL': {
    translation: nlNLTranslations,
  },
  'de-DE': {
    translation: deDETranslations
  },
  'pt-PT': {
    translation: ptPTTranslations
  },
  'fr-FR': {
    translation: frFRTranslations
  }
}

const langFallbacks = {
  en: ['en', 'en-US'],
  'en-UK': ['en', 'en-US'],
  es: ['es', 'es-ES'],
  ja: ['ja', 'ja-JP'],
  ko: ['ko', 'ko-KR'],
  nl: ['nl', 'nl-NL'],
  de: ['de', 'de-DE'],
  pt: ['pt', 'pt-PT'],
  'pt-BR': ['pt', 'pt-PT'],
  fr: ['fr', 'fr-FR']
}

let navigatorLanguages
try {
  const knownLangKeys = Object.keys(langResources)
  const knownFallbackKeys = Object.keys(langFallbacks)
  const resourceKeys = knownLangKeys.concat(knownFallbackKeys)
  i18next.resourceKeys = resourceKeys
  navigatorLanguages = navigator.languages.filter((item) => resourceKeys.includes(item))
} catch (e) {
  navigatorLanguages = []
}

let langDefault = 'en-US'
if (navigatorLanguages.length > 0) {
  langDefault = navigatorLanguages.shift()
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: langDefault,
    fallbackLng: langFallbacks,
    resources: langResources,
  })

export default i18next
